import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.compat.css';

import Layout from '../components/layout';
// import ScrollAnimation from '../components/scrollAnimation';

const DogGrooming = ({ data }) => {
  var pageTitle = 'Dog Grooming';

  return (
    <Layout
      slug="grooming"
      title={pageTitle}
      description="Learn more about the variety of grooming sessions we offer to ensure we have something for every pet!"
    >
      <h1>{pageTitle}</h1>

      <p>
        With over 30 years of experience on staff our dog groomers are here to
        ensure that your canine has a safe and relaxing day at the spa. We offer
        all breed dog grooming and have a flexible schedule to cater to your
        needs. We offer both weekend and evening appointments. If your pet is staying with us at the kennel we can provide a
        full service departure groom to ensure they are fresh and clean before
        returning home.
      </p>

      <p className="mb-20">
        We have several different types of grooming sessions available to ensure
        we have something that fits for every dog. This allows you to choose the
        best fit for your dog to ensure they have the best experience at Very
        Important Pets.
      </p>

      <section className="flex flex-col lg:flex-row lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Standard Grooming</h2>
            <p>
              For pets who are used to the grooming procedure and comfortable
              being crated we can provide a more typical grooming experience. As
              we do have multiple groomers on staff there will likely be more
              than one pet in the building during these appointments.
            </p>
          </ScrollAnimation>
        </div>

        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image2)}
              alt="groomed dog"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section
        id="private-kennel-free-grooming"
        className="flex flex-col lg:flex-row-reverse lg:items-center mb-20"
      >
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Private Kennel Free Grooming</h2>
            <p>
              Our private kennel free grooming is perfect for pets who may not
              get along with other pets, have anxiety or are new to grooming
              procedure. This ensures your pet has a private one-on-one
              experience with no other pets in the grooming facility and no
              kennel time. We do require that you pick up your pet at the
              requested time to ensure that no other pets are in the facility at
              the same time.
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image3)}
              alt="groomed dog"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col items-center text-center mb-20">
        <span className="h2">
          Wondering who will be working with your pets?
        </span>
        <Link to="/about/#grooming-team" className="btn btn-light">
          Meet the Grooming Team
        </Link>
      </section>

      <section className="flex flex-col lg:flex-row lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Senior Sessions</h2>
            <p>
              Our senior sessions are designed for dogs who are unable to
              physically unable to tolerate a full grooming session. We instead
              split up the session into multiple shorter times to help ensure
              they do not become fatigued. This is also a great option for dogs
              who have disabilities or joint issues and may struggle with the
              physical demands required during a normal grooming session.
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image1)}
              alt="groomed dog"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section
        id="at-home-nail-trims"
        className="flex flex-col lg:flex-row-reverse lg:items-center mb-20"
      >
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>At home Nail Trims</h2>
            <p>
              This is a great option to help pets who are not comfortable
              travelling or in new surroundings have a relaxing and enjoyable
              nail trim experience in their own home. For families with more
              than one pet it can be very helpful as we come to you so it
              eliminates the need to load everyone up in the vehicle.
            </p>
            <p>
              Note: Please understand that during the pandemic we will not be
              entering homes and will instead perform this service outside for
              the safety of everyone.
            </p>
          </ScrollAnimation>
        </div>

        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image5)}
              alt="nail trimmers"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Puppy Sessions</h2>
            <p>
              This session is available for puppies under 5 months old to help
              them get used to the grooming process. For the first visit its
              nice just to do a puppy trim (face, feet and sanitary trim) This
              helps them get them used to the different smells and sounds at the
              grooming salon without being overwhelming and gives them a chance
              to meet their groomer. At about 6 months they should then be ready
              for their first haircut!
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image6)}
              alt="groomed puppy"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <section className="flex flex-col lg:flex-row-reverse lg:items-center mb-20">
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2>Creative Grooming</h2>
            <p>
              One of the newest aspects of grooming we have started exploring at
              Very Important Pets is creative grooming! These grooms include
              many unique features catered to your pet and their individual
              personalities. Creative grooming can include many different things
              such as coat carving, nail wraps and pet safe hair dye!
            </p>
          </ScrollAnimation>
        </div>
        <div className="lg:w-1/2">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <GatsbyImage
              image={getImage(data.image4)}
              alt="groomed dog"
              className="mb-4 mx-auto res-square-400 rounded-xl"
            />
          </ScrollAnimation>
        </div>
      </section>

      <p>
        Please <Link to="/contact/">contact us</Link> to book an appointment! We
        are occasionally able to offer evening and weekend appointments.
      </p>
    </Layout>
  );
};

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "dog_grooming1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "dog_grooming2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "dog_grooming3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          aspectRatio: 0.8
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    image4: file(relativePath: { eq: "creative_grooming1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image5: file(relativePath: { eq: "nail_trim.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image6: file(relativePath: { eq: "puppy_groom.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          aspectRatio: 0.8
          transformOptions: { cropFocus: CENTER }
        )
      }
    }
  }
`;

export default DogGrooming;
